import React, { forwardRef, useEffect, useRef, useState } from "react";
import { BiUpload } from "react-icons/bi";
import { useDispatch } from "react-redux";
import Select, { components } from "react-select";
import { Form, InputGroup } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { Controller, useFormContext, get } from "react-hook-form";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment";

import { setLoader } from "../store/reducer";
import Dropdown from "../assets/images/dropdown.png";
import { ApiCall, ApiFileUpload, getlikeRTTweet, getTwitterUserName } from "../utils/ApiUtils";
import ColorButton from "./ColorButton";

function FormInput({
  name,
  defaultValue,
  selected,
  onChange,
  placeholder,
  type = "text",
  label,
  disabled,
  options = [],
  className = "",
  value,
  suffix,
  prefix,
  mask,
  fileUploadName,
  inputClassName,
  isMulti,
  selectedValue,
  onKeyPress,
  rows,
  allowListId,
  minDateTime,
  accept,
  onBlur,
  isSearchable,
  ...rest
}) {
  const methods = useFormContext();
  const dispatch = useDispatch();
  const [stopToast, setStopToast] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const uploadIcon = useRef(null);

  const error = get(methods.formState.errors, name);

  const fieldRegister = methods.register(name, {
    onChange: onChange ? onChange : () => {},
    onBlur: onBlur ? onBlur : () => {},
  });

  useEffect(() => {
    if (stopToast) {
      setTimeout(() => {
        setStopToast(false);
      }, 4000);
    }
  }, [stopToast]);

  const handelCreatableOnChange = async (field, value, dd) => {
    value = value.filter((v, i, a) => a.findIndex((v2) => v2.value === v.value) === i);
    if (field.name.includes("twitterActivity")) {
      // let isValid = true;
      value = value.map((item) => {
        const parsedVal = getTwitterUserName(item.value);
        return { ...item, label: parsedVal, value: parsedVal };
        // if (parsedVal)
        // else isValid = false;
      });
      // if (!isValid) {
      //   toast.error("Invalid link");
      //   return;
      // }
    }
    if (field.name.includes("tweets") && dd?.action === "create-option") {
      const [val, ...rest] = value;
      if (val) {
        try {
          const parsedVal = getlikeRTTweet(val.value);
          if (!parsedVal) {
            setStopToast(true);
            !stopToast && toast.error("Invalid link");
            return;
          }
          const tweetId = val?.value?.split("?")[0].split("/").pop();
          dispatch(setLoader(true));

          const resp = await ApiCall("GET", `/rest/allowlist/varifyTweetById/${allowListId}/${tweetId}`);
          if (resp.data) {
            var now = new Date();
            var then = new Date(resp?.data?.data?.created_at);
            var diffInDays = Math.round((then - now) / (1000 * 60 * 60 * 24));
            if (diffInDays > -365) {
              value = [
                ...rest,
                {
                  label: parsedVal,
                  value: val.value,
                  author_id: resp?.data?.data?.author_id,
                  created_at: resp?.data?.data?.created_at,
                },
              ];
            } else {
              dispatch(setLoader(false));
              setStopToast(true);
              !stopToast && toast.error("Tweet attached in qualification criteria should not be more than a year old.");
              return;
            }
          }
          dispatch(setLoader(false));
        } catch (error) {
          setStopToast(true);
          !stopToast && toast.error(error?.response?.data?.message || "Invalid link");
          dispatch(setLoader(false));
          return;
        }
      }
    }

    field.onChange(value);
  };

  return (
    <>
      {type !== "checkbox" && label && <Form.Label>{label} </Form.Label>}
      <Form.Group
        className={`form-group ${error ? "form-error" : ""} ${error?.type === "required" ? "dot " : ""}` + className}
      >
        {(() => {
          switch (type) {
            case "password":
              return (
                <InputGroup className="input_group">
                  <Form.Control
                    type={isShowPassword ? "text" : "password"}
                    placeholder={placeholder}
                    onWheelCapture={(e) => {
                      e.target.blur();
                    }}
                    disabled={disabled}
                    {...fieldRegister}
                    autoComplete="off"
                  />
                  <InputGroup.Text onClick={() => setIsShowPassword(!isShowPassword)} id="create-password">
                    <BiUpload />
                  </InputGroup.Text>
                </InputGroup>
              );

            case "select":
              return (
                <Controller
                  control={methods.control}
                  name={name}
                  render={({ field }) => (
                    <Select
                      {...field}
                      classNamePrefix="select form-select-custom"
                      options={options}
                      isMulti={isMulti}
                      onChange={(data) => {
                        if (isMulti) field.onChange(data);
                        else field.onChange(data?.value ? String(data?.value) : "");
                      }}
                      value={
                        isMulti
                          ? field.value
                          : options.find((item) => item.value === field.value)
                          ? options.find((item) => item.value === field.value)
                          : options[0]
                      }
                      isClearable={false}
                      hideSelectedOptions
                      defaultValue={selectedValue}
                      isDisabled={disabled}
                      components={{
                        IndicatorSeparator: null,
                        DropdownIndicator: (props) => (
                          <components.DropdownIndicator {...props}>
                            <img src={Dropdown} alt="icon" className="icon" />
                          </components.DropdownIndicator>
                        ),
                      }}
                    />
                  )}
                />
              );

            case "checkbox":
              return (
                <Form.Check
                  type={type}
                  placeholder={placeholder}
                  label={label}
                  disabled={disabled}
                  {...fieldRegister}
                />
              );

            case "file":
              return (
                <div className="box" style={{ height: 52 }}>
                  <Controller
                    name={name}
                    control={methods.control}
                    render={({ field }) => {
                      const fileName = field.value?.name || methods.getValues(fileUploadName) || placeholder;
                      return (
                        <>
                          <section
                            className="outputName"
                            style={{ color: methods.getValues(fileUploadName) ? "#5A5A5A" : "" }}
                          >
                            {fileName}
                          </section>
                          <input
                            type="file"
                            accept={accept ? accept : ".png, .jpg, .jpeg"}
                            className="inputFile inputFile-1"
                            ref={uploadIcon}
                            title={fileName}
                            onChange={async (e) => {
                              if (e.target.files.length === 0) return;
                              if (fileUploadName) {
                                try {
                                  field.onChange(e.target.files[0] || "");
                                  methods.setValue(fileUploadName, "");
                                  const isVaild = await methods.trigger(name);
                                  if (isVaild) {
                                    dispatch(setLoader(true));
                                    const responseData = await ApiFileUpload(
                                      e.target.files[0],
                                      typeof field.value === "string" ? field.value : ""
                                    );
                                    field.onChange(responseData.id);
                                    methods.setValue(fileUploadName, responseData.originalFilename, {
                                      shouldTouch: true,
                                    });
                                    dispatch(setLoader(false));
                                  }
                                } catch (error) {
                                  console.log(error);
                                  dispatch(setLoader(false));
                                  toast.error("Something went wrong while file upload.");
                                }
                              } else {
                                field.onChange(e.target.files[0]);
                              }
                            }}
                          />
                        </>
                      );
                    }}
                  />

                  <BiUpload onClick={() => uploadIcon.current.click()} style={{ cursor: "pointer", zIndex: 1 }} />
                </div>
              );

            case "creatableSelect":
              return (
                <Controller
                  name={name}
                  control={methods.control}
                  render={({ field }) => {
                    return (
                      <CreatableSelect
                        isClearable
                        isDisabled={disabled}
                        isMulti
                        closeMenuOnSelect={false}
                        isSearchable={isSearchable}
                        hideSelectedOptions={false}
                        placeholder={placeholder || ""}
                        classNamePrefix="form-select-custom"
                        options={options.length > 0 ? options : []}
                        components={{
                          IndicatorSeparator: null,
                          DropdownIndicator: (props) =>
                            field.name.includes("roles") && (
                              <components.DropdownIndicator {...props}>
                                <img src={Dropdown} alt="icon" className="icon" />
                              </components.DropdownIndicator>
                            ),
                          Input: (props) => (
                            <components.Input
                              {...props}
                              onPaste={async (e) => {
                                const dd = e.clipboardData.getData("text");
                                if (field.name.includes("tweets")) {
                                  const parsedVal = getlikeRTTweet(dd);
                                  if (parsedVal === false) {
                                    setStopToast(true);
                                    !stopToast && toast.error("Invalid link");
                                  } else {
                                    handelCreatableOnChange(
                                      field,
                                      dd
                                        .split(",")
                                        .map((item) => ({ label: item, value: item }))
                                        .concat(field.value),
                                      {
                                        action: "create-option",
                                      }
                                    );
                                  }
                                } else {
                                  handelCreatableOnChange(
                                    field,
                                    dd
                                      .split(",")
                                      .map((item) => ({ label: item, value: item }))
                                      .concat(field.value),
                                    {
                                      action: "create-option",
                                    }
                                  );
                                }
                              }}
                            />
                          ),
                          Option: (props) => {
                            return (
                              <div>
                                <components.Option {...props}>
                                  <input type="checkbox" checked={props.isSelected} onChange={() => null} />
                                  <label>{props.label}</label>
                                </components.Option>
                              </div>
                            );
                          },
                        }}
                        {...field}
                        onChange={(value, dd) => handelCreatableOnChange(field, value, dd)}
                      />
                    );
                  }}
                />
              );

            case "groupCheckbox":
              return (
                <div>
                  {options.map((item) => (
                    <Form.Check
                      inline
                      type="radio"
                      key={item.value}
                      label={item.label}
                      value={item.value}
                      {...fieldRegister}
                    />
                  ))}
                </div>
              );

            case "textarea":
              return (
                <textarea
                  onKeyPress={onKeyPress}
                  placeholder={placeholder}
                  disabled={disabled}
                  {...rest}
                  {...fieldRegister}
                  className={`form-control ${inputClassName ? inputClassName : ""} ${type === "date" && "select-date"}`}
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => {
                    e.target.placeholder = placeholder;
                    fieldRegister.onBlur(e);
                  }}
                />
              );

            case "color":
              return <ColorButton name={name} disabled={disabled} placeholder={placeholder || ""} />;

            case "datepicker":
              return (
                <Controller
                  name={name}
                  control={methods.control}
                  render={({ field }) => {
                    const ExampleCustomInput = forwardRef(({ value, onClick, className }, ref) => (
                      <input
                        disabled={disabled}
                        type="text"
                        onClick={onClick}
                        ref={ref}
                        className={className}
                        value={value}
                        // value={`${value} ${moment().tz(moment.tz.guess()).format("z")}`}
                        readOnly
                      />
                    ));

                    return (
                      <DatePicker
                        selected={new Date(field.value)}
                        minDate={minDateTime}
                        minTime={minDateTime}
                        onChange={(date) => {
                          field.onChange(date.toISOString());
                        }}
                        timeInputLabel="Time:"
                        dateFormat="do MMM, yyyy h:mm aa"
                        customInput={<ExampleCustomInput className="datePicker-custom-input" />}
                        maxTime={moment().endOf("day").toDate()}
                        showTimeSelect
                        timeIntervals={15}
                      />
                    );
                  }}
                />
              );

            default:
              return (
                <input
                  type={type}
                  onKeyPress={onKeyPress}
                  placeholder={placeholder}
                  disabled={disabled}
                  rows={rows}
                  {...rest}
                  {...fieldRegister}
                  className={`form-control ${inputClassName ? inputClassName : ""} ${type === "date" && "select-date"}`}
                />
              );
          }
        })()}

        {error && error?.type !== "required" && (
          <div className="error-text mt-1">
            <span className="info">i</span>
            <span>{error?.message}</span>
          </div>
        )}
      </Form.Group>
    </>
  );
}

export default FormInput;
