import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useMetaMask } from "metamask-react";
import signMessage from "../../utils/signer";
// import config from "../../utils/config.json";
import { useAuth } from "../../hooks/useAuth";
import ArrowRight from "../../assets/images/arrow.svg";
import { useDispatch } from "react-redux";
import { setLoader } from "../../store/reducer";
import { toast } from "react-toastify";

const Connect = () => {
  document.title = "Hyp3 - Connect";

  const dispatch = useDispatch();
  const { login } = useAuth();
  const { status, connect, account, chainId } = useMetaMask();

  // useEffect(() => {
  //   window.ethereum
  //     ?.request({
  //       method: "wallet_switchEthereumChain",
  //       params: [{ chainId: config.chainId }],
  //     })
  //     .then(() => {});
  // }, [chainId]);

  const metamaskHandler = () => {
    connect()
      .then((res) => {
        if (account !== null) {
          //check for existing token in local storage
          signMessage(res[0]).then((signature) => {
            if (signature) {
              login(signature);
            } else {
              toast.error("Please authenticate to proceed further");
            }
          });
        }
      })
      .catch((err) => {
        if (err?.code === 4001) toast.error("Connection failed! Please try again.");
        dispatch(setLoader(false));
      });
  };

  return (
    <div className="container">
      <div className="connect-wallet-wrap">
        <div>
          <NavLink className="navbar-brand" to="/">
            <img src="images/hyp3-logo.png" alt="logo" />
          </NavLink>
        </div>

        <button onClick={metamaskHandler} className="btn connect-outline-btn">
          {status === "connected" ? "Authenticate" : "Connect Wallet"} <img src={ArrowRight} alt="ArrowRight" />
        </button>
        <button className="btn install-hyp3-btn">
          <a
            className="d-flex"
            target="_blank"
            href="https://discord.com/api/oauth2/authorize?client_id=948949555729281034&permissions=8&scope=bot%20applications.commands"
          >
            <span className="btn-text">Install HYP3R Bot</span>
            <span className="discord-img">
              <img style={{ maxWidth: "30px" }} src="images/discord-white.png" alt="icon2" />
            </span>
          </a>
        </button>
      </div>
    </div>
  );
};
export default Connect;
