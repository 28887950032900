import React, { useEffect } from "react";
import BasicForm from "./components/BasicForm";
import Header from "../../components/Header";
import GroupsForm from "./components/GroupsForm";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentFormStep, setDiscordServerData } from "../../store/reducer";
import { ApiCall } from "../../utils/ApiUtils";

const BasicConfig = () => {
  document.title = "Hyp3 - Launch";

  const dispatch = useDispatch();

  const currentFormStep = useSelector(({ currentFormStep }) => currentFormStep);

  useEffect(() => {
    window.scrollTo(0, 0);
    ApiCall("GET", `/http/discord/getBotGuilds`).then((botGuildsResponse) => {
      dispatch(setDiscordServerData(botGuildsResponse?.data));
    });
  }, []);

  const goBack = (current) => {
    if (current < currentFormStep) dispatch(setCurrentFormStep(currentFormStep - 1));
  };

  const goNext = () => dispatch(setCurrentFormStep(currentFormStep + 1));

  return (
    <div>
      <Header isConfirmBack={true} />
      <div className="comon-all-body float-start w-100 mt-md-5 mt-4">
        <div className="comon-div">
          <div className="container px-sm-0">
            <h1 className="main-haeding text-center text-white mb-2"> Launch Allowlist </h1>
            <p className="d-block mx-auto text-center text-white fs-20 mb-2 futura-light">
              Launch an allowlist to gauge and register interest from your community
            </p>
            <div className="comon-from my-md-5 my-4">
              <div className="blur"></div>
              <div className="main-forms">
                <div className="link-gopage mb-5">
                  <ul className="list-unstyled d-flex justify-content-center align-items-center mb-1">
                    <li className={`${currentFormStep === 1 ? "active" : ""}`} onClick={() => goBack(1)}>
                      <div className="comon-btn-nomber text-center d-table">
                        <span className="count-div">1</span>
                        <p className="fs-20"> Basic Config </p>
                      </div>
                    </li>
                    <li className={`${currentFormStep === 2 ? "active" : ""}`} onClick={() => goBack(2)}>
                      <div className="comon-btn-nomber text-center d-table">
                        <span className="count-div">2</span>
                        <p className="fs-20"> Configure Groups </p>
                      </div>
                    </li>
                  </ul>
                </div>
                {currentFormStep === 1 && <BasicForm goNext={goNext} />}
                {currentFormStep === 2 && <GroupsForm />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BasicConfig;
