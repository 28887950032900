import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
// import { FaBars } from "react-icons/fa";
import { useMetaMask } from "metamask-react";
import { useNavigate } from "react-router-dom";
import { BsXCircleFill, BsArrowRight, BsFillCircleFill } from "react-icons/bs";

import { cutAddress } from "../utils/common";
import ArrowRight from "../assets/images/arrow.svg";
import { setCurrentFormStep } from "../store/reducer";

const Header = ({ isConfirmBack = false }) => {
  // const [menu, setMenu] = useState(1);
  const { account } = useMetaMask();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const goToHome = () => {
    if (isConfirmBack) {
      handleShow();
    } else {
      navigate("/manage");
    }
  };

  return (
    <>
      <nav id="custom-navbar" className="custom-navbar navbar navbar-expand-lg navbar-light float-start w-100">
        <div className="container px-4">
          <div className="navbar-brand" style={{ cursor: "pointer" }} onClick={goToHome}>
            <img src="images/hype-logo.png" alt="logo" />
          </div>
          {/* <div className="marketplace-head">
            <h2 className="mb-0">Allowlist</h2>
          </div> */}
          {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li onClick={() => setMenu(1)} className={`nav-item ${menu === 1 ? "active" : ""}`}>
                <NavLink className="nav-link" to="/">
                  Marketplace
                </NavLink>
              </li>
              <li onClick={() => setMenu(2)} className={`nav-item ${menu === 2 ? "active" : ""}`}>
                <NavLink className="nav-link active" to="/">
                  Mint
                </NavLink>
              </li>
              <li onClick={() => setMenu(3)} className={`nav-item ${menu === 3 ? "active" : ""}`}>
                <NavLink className="nav-link" to="/">
                  Stake
                </NavLink>
              </li>
            </ul>
          </div> */}
          <button type="button" className="btn active-id-btn">
            <span className="icon-n3">
              <BsFillCircleFill />
            </span>
            {cutAddress(account, 5)}
          </button>
          {/* <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#mobile-menu">
            <FaBars />
          </button> */}
        </div>
      </nav>
      <div
        className="offcanvas offcanvas-start mobile-menu-div"
        tabIndex="-1"
        id="mobile-menu"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <button type="button" className="close-menu btn" data-bs-dismiss="offcanvas" aria-label="Close">
            <BsXCircleFill />
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="head-contact">
            <a href="index.html" className="logo-side">
              <img src="images/8080.png" alt="logo" />
            </a>
            <div className="mobile-menu-sec mt-3">
              <ul className="list-unstyled">
                <li data-bs-dismiss="offcanvas">
                  <NavLink className="nav-link" to="/">
                    Mint
                  </NavLink>
                </li>
                <li data-bs-dismiss="offcanvas">
                  <NavLink className="nav-link" to="/">
                    Stake
                  </NavLink>
                </li>
                <li data-bs-dismiss="offcanvas">
                  <NavLink className="nav-link" to="/">
                    Marketplace
                  </NavLink>
                </li>
                <li data-bs-dismiss="offcanvas">
                  <NavLink to="/" className="btn connect-bnt">
                    Launched Mint <BsArrowRight />
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {show && (
        <Modal className="delete-confirmation" centered show={show} onHide={handleClose}>
          <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
          <Modal.Body>
            <p style={{ maxWidth: "280px" }}>
              All unsaved changes will be lost. Are you sure you want to <span>leave</span> this page?
            </p>
          </Modal.Body>
          <Modal.Footer className="justify-content-start border-0">
            <button
              onClick={() => {
                navigate("/manage");
                handleClose();
                dispatch(setCurrentFormStep(1));
              }}
              className="btn next-btn m-0"
            >
              Leave
              <span className="d-flex">
                <img src={ArrowRight} alt="Icon" />
              </span>
            </button>
            <button onClick={() => handleClose()} className="btn text-white">
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default Header;
