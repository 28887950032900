import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BsX } from "react-icons/bs";
import { FaDiscord } from "react-icons/fa";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useFormPersist from "react-hook-form-persist";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { projectConfigValidation } from "../validationSchema";
import { ApiCall, ApiGetFile, API_ALLOWLIST_URL } from "../../../utils/ApiUtils";
import FormInput from "../../../components/FormInput";
import ArrowRight from "../../../assets/images/arrow.svg";
import { setIsEditUserId, setLoader, setSavedFirstStep, setSaveDraftData } from "../../../store/reducer/index";

const BasicForm = ({ goNext }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [showToast, setShowToast] = useState(true);
  const saveDraftData = useSelector(({ saveDraftData }) => saveDraftData);
  const savedFirstStep = useSelector(({ savedFirstStep }) => savedFirstStep);
  const isEditUserId = useSelector(({ isEditUserId }) => isEditUserId);
  const currentFormStep = useSelector(({ currentFormStep }) => currentFormStep);

  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const userId = isEditUserId;
  const isEditMode = Boolean(isEditUserId) ? true : false;
  const methods = useForm({
    resolver: yupResolver(projectConfigValidation),
    defaultValues: {
      primaryColour: "#E64242",
      secondaryColour: "#A742E6",
      urlSlug: "",
      allowListName: "",
    },
    mode: "onChange",
  });
  const {
    handleSubmit,
    control,
    watch,
    trigger,
    setValue,
    getValues,
    formState: { errors, touchedFields },
    clearErrors,
    getFieldState,
  } = methods;

  useFormPersist("basicForm", {
    watch,
    setValue,
    storage: window.localStorage,
  });

  const discord = watch("discord");
  const twitter = watch("twitter");
  const projectLogo = watch("projectLogo");
  const projectIcon = watch("projectIcon");
  const projectBackgroundImage = watch("projectBackgroundImage");

  window.onpopstate = () => {
    if (window.location.pathname === "/launch" && currentFormStep === 1) {
      navigate("/manage");
    }
  };

  useEffect(() => {
    if (userId) {
      populateEditData();
      return () => {
        dispatch(setIsEditUserId(false));
        localStorage.removeItem("basicForm");
      };
    }
  }, [userId]);

  const populateEditData = async () => {
    try {
      dispatch(setLoader(true));
      const result = await ApiCall("GET", `/rest/allowlist/${userId}`);
      const editUserData = result?.data;

      if (editUserData) {
        setValue("allowListName", editUserData?.allowListName);
        setValue("projectName", editUserData?.projectName);
        setValue("projectDescription", editUserData?.projectDescription);
        setValue("urlSlug", editUserData?.urlSlug);
        setValue("primaryColour", editUserData?.primaryColour);
        setValue("secondaryColour", editUserData?.secondaryColour);
        setValue("projectWebsite", editUserData?.projectWebsite);
        setValue("discord", editUserData?.discord);
        setValue("twitter", editUserData?.twitter);
        setValue("discordInviteLink", editUserData?.discordInviteLink);

        const dd1 = await ApiGetFile(editUserData?.projectBackgroundImage);
        const dd2 = await ApiGetFile(editUserData?.projectLogo);
        const dd3 = await ApiGetFile(editUserData?.projectIcon);

        setValue("projectBackgroundName", dd1 ? dd1?.originalFilename : "");
        setValue("projectBackgroundImage", editUserData?.projectBackgroundImage || "");

        setValue("projectLogoName", dd2 ? dd2?.originalFilename : "");
        setValue("projectLogo", editUserData?.projectLogo || "");

        setValue("projectIconName", dd3 ? dd3?.originalFilename : "");
        setValue("projectIcon", editUserData?.projectIcon || "");
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
      toast.error("Failed to load. Please try again later.");
      console.log(error);
    }
  };

  useEffect(() => {
    if (code !== null) {
      if (searchParams.get("state")) {
        if (!Object.keys(getValues("twitter") || {}).length) {
          ApiCall("GET", `/http/twitter/getAccessToken?code=${code}&state=${searchParams.get("state")}`).then(
            (result) => {
              setValue("twitter", result.data, { shouldTouch: true });
            }
          );
        }
      } else {
        if (!Object.keys(getValues("discord") || {}).length) {
          ApiCall("GET", `/http/discord/callback?code=${code}`).then((result) => {
            setValue("discord", result.data, { shouldTouch: true });
          });
        }
      }
    }
    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
    // eslint-disable-next-line
  }, [code, searchParams]);

  useEffect(() => {
    if (savedFirstStep) {
      ApiCall("GET", `/rest/allowlist/${savedFirstStep.id}`).then(async ({ data }) => {
        const dd1 = await ApiGetFile(data?.projectBackgroundImage);
        const dd2 = await ApiGetFile(data?.projectLogo);
        const dd3 = await ApiGetFile(data?.projectIcon);

        if (dd1 && data?.projectBackgroundImage !== undefined)
          setValue("projectBackgroundName", dd1?.originalFilename || "");
        if (dd2 && data?.projectLogo !== undefined) setValue("projectLogoName", dd2?.originalFilename || "");
        if (dd3 && data?.projectIcon !== undefined) setValue("projectIconName", dd3?.originalFilename || "");
      });
    }
  }, [savedFirstStep]);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    const allVal = getValues();
    Object.keys(allVal).forEach((key) => (allVal[key] === undefined || allVal[key] === "") && delete allVal[key]);
    if (Object.keys(allVal).length > 2) {
      if (window.confirm("Sure about leaving this page? All unsaved changes will be lost.")) {
        navigate("/manage");
      }
    }
  };

  const onSubmit = async (data) => {
    try {
      const isInvalid = await checkAllowListName(data?.urlSlug, data?.allowListName, true);
      if (isInvalid) {
        return;
      }

      if (!data["twitter"]) delete data["twitter"];

      if (Object.keys(touchedFields).length > 0) {
        if (isEditMode) {
          dispatch(setLoader(true));
          delete data["urlSlug"];
          delete data["discord"];
          await ApiCall("PUT", "/rest/allowlist/basicConfig/" + userId, {
            basicConfig: data,
          });
        } else {
          if (savedFirstStep || saveDraftData) {
            dispatch(setLoader(true));
            const id = savedFirstStep ? savedFirstStep?.id : saveDraftData?.id;
            await ApiCall("PUT", "/rest/allowlist/basicConfig/" + id, {
              basicConfig: data,
            });
            const oldData = savedFirstStep ? savedFirstStep : saveDraftData;
            dispatch(setSavedFirstStep({ ...oldData, ...data }));
          } else {
            dispatch(setLoader(true));
            const resp = await ApiCall("POST", "/rest/allowlist/basicConfig", {
              basicConfig: data,
            });
            dispatch(setSavedFirstStep(resp.data));
          }
        }
        dispatch(setLoader(false));
        toast.success(isEditMode ? "Updated successfully!" : "Draft saved!");
      }

      isEditMode ? navigate("/manage", { state: state }) : goNext();
      const element = document.getElementById("custom-navbar");
      element.scrollIntoView();
    } catch (error) {
      dispatch(setLoader(false));
      console.log(error);
      toast.error(error?.response?.data?.message || "Something went wrong.");
    }
  };

  const saveDraft = async () => {
    try {
      const allVal = getValues();
      await trigger(["allowListName", "projectName", "urlSlug", allVal.projectWebsite ? "projectWebsite" : ""]);
      // const projectWebsiteState = getFieldState("projectWebsite");
      const allowListName = getFieldState("allowListName");
      const urlSlug = getFieldState("urlSlug");

      if (allowListName.error || !allVal.projectName || urlSlug.error) {
        window.scrollTo(0, 300);
        errors?.allowListName?.ref.focus();
        toast.error(
          <p className="text-center">
            To save draft, fill in the fields <br /> marked by red dots.
          </p>
        );
        clearErrors([
          "primaryColour",
          "secondaryColour",
          "projectDescription",
          "discordInviteLink",
          "projectLogo",
          "projectBackgroundImage",
          "projectWebsite",
          "discord",
        ]);
        return;
      }

      Object.keys(allVal).forEach((key) => !allVal[key] && delete allVal[key]);

      const isInvalid = await checkAllowListName(allVal?.urlSlug, allVal?.allowListName, false);
      if (isInvalid) {
        return;
      }

      if (saveDraftData && saveDraftData.urlSlug === allVal.urlSlug) delete allVal["urlSlug"];

      if (typeof errors?.projectLogo === "object" && Object.keys(errors?.projectLogo)?.length !== 0) {
        return;
      }

      if (
        typeof errors?.projectBackgroundImage === "object" &&
        Object.keys(errors?.projectBackgroundImage)?.length !== 0
      ) {
        return;
      }

      const isUpdate = saveDraftData && Object.keys(saveDraftData).length > 0;
      dispatch(setLoader(true));
      const resp = await ApiCall(
        isUpdate ? "PUT" : "POST",
        `/rest/allowlist/basicConfig${isUpdate ? "/" + saveDraftData.id : ""}`,
        {
          basicConfig: allVal,
        }
      );

      dispatch(setLoader(false));
      if (resp.data) {
        dispatch(setLoader(false));
        dispatch(setSaveDraftData(resp.data));
        if (saveDraftData) toast.success("Draft saved!");
        else toast.success("Draft saved!");
      }
    } catch (error) {
      console.log(error);

      dispatch(setLoader(false));
      toast.error(error?.response?.data?.message || "Something went wrong.");
    }
  };

  const onDiscordClick = async (isRemove) => {
    if (isRemove) {
      setValue("discord", null);
    } else {
      window.open(API_ALLOWLIST_URL + "/http/discord/request/creator", "_self");
    }
  };

  const onTwitterClick = async (isRemove) => {
    if (isRemove) {
      setValue("twitter", null);
    } else {
      window.open(API_ALLOWLIST_URL + `/http/twitter/request?state=${new Date().getTime()}`, "_self");
    }
  };

  const checkAllowListName = async (urlSlug, allowListName, isIdSlug) => {
    const result = await ApiCall("GET", `/rest/allowlist`);
    const sameAllowlistName = result?.data
      ?.filter((item) => (isIdSlug ? item?.urlSlug !== urlSlug : item?.id !== saveDraftData?.id))
      .filter((item) => item.allowListName === allowListName);
    if (sameAllowlistName?.length > 0) {
      if (showToast) {
        toast.error("Allowlist names must be unique.");
        setShowToast(false);
        setTimeout(() => {
          setShowToast(true);
        }, 3000);
      }
      return true;
    } else {
      return false;
    }
  };

  const checkURLSlug = async (value) => {
    try {
      const resp = await ApiCall("GET", `/rest/allowlist/slugAvailabilityCheck/${value}`);
      if (!resp.data) {
        toast.error("URL slug must be unique. Please choose another one.");
      }
    } catch (error) {
      toast.error("URL slug verification failed");
    }
  };

  useEffect(() => {
    if (
      projectBackgroundImage?.name === undefined &&
      typeof projectBackgroundImage === "object" &&
      Object.keys(projectBackgroundImage)?.length === 0
    ) {
      setValue(`projectBackgroundImage`, undefined);
      setValue(`projectBackgroundName`, "");
    }
    if (projectLogo?.name === undefined && typeof projectLogo === "object" && Object.keys(projectLogo)?.length === 0) {
      setValue(`projectLogo`, undefined);
      setValue(`projectLogoName`, "");
    }
    if (projectIcon?.name === undefined && typeof projectIcon === "object" && Object.keys(projectIcon)?.length === 0) {
      setValue(`projectIcon`, undefined);
      setValue(`projectIconName`, "");
    }
  }, [projectIcon, projectLogo, projectBackgroundImage]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await handleSubmit(onSubmit)();
          // eslint-disable-next-line
          for (let [key, value] of Object.entries(methods.formState.errors)) {
            if (value?.type === "required" || value?.type === "typeError") {
              if (showToast) {
                toast.error(<p className="text-center">Fields marked by a red dot are required.</p>);
                setShowToast(false);
                setTimeout(() => {
                  setShowToast(true);
                }, 3000);
              }
              break;
            }
          }
        }}
        onKeyDown={(e) => e.code === "Enter" && e.preventDefault()}
      >
        <div className="inside-div-cm col-lg-10 w-90 mx-auto d-block">
          <h3> Basic Info </h3>
          <p> Let’s name your project! </p>
          <div className="row">
            <div className="col-lg-6">
              <FormInput name="allowListName" type="text" placeholder="Allowlist Name" />
            </div>
            <div className="col-lg-6">
              <FormInput name="projectName" type="text" placeholder="Project Name" />
            </div>
            <div className="col-lg-12">
              <FormInput
                rows={4}
                name="projectDescription"
                type="textarea"
                placeholder="Project Description (Optional)"
              />
            </div>
            <div className="col-lg-12"></div>
          </div>
        </div>
        <div className="inside-div-cm col-lg-10 w-90 mx-auto d-block">
          <h3 style={{ opacity: isEditMode ? 0.5 : 1 }}> Registration Page URL </h3>
          <p style={{ maxWidth: "915px", opacity: isEditMode ? 0.5 : 1 }}>
            The slug allows users to find your allowlist registration page! The page will be launched at{" "}
            <b className="links-nm text-lowercase" style={{ fontSize: 18 }}>
              https://&lt;url slug&gt;.{process.env.REACT_APP_REGISTER_REDIRECT_URL}
            </b>
          </p>
          <div className="row">
            <div className="col-lg-12">
              <div
                className={`form-group ${errors.urlSlug?.message ? "form-error" : ""} ${
                  errors.urlSlug?.type === "required" ? "dot " : ""
                }`}
              >
                <Controller
                  name="urlSlug"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="text"
                      className="form-control"
                      placeholder="URL Slug"
                      disabled={isEditMode}
                      {...field}
                      onBlur={() => field.value && checkURLSlug(field.value)}
                    />
                  )}
                />

                {errors.urlSlug && errors.urlSlug?.type !== "required" && (
                  <div className="error-text mt-1">
                    <span className="info">i</span>
                    <span>{errors.urlSlug.message}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="inside-div-cm col-lg-10 w-90 mx-auto d-block">
          <h3> Look & Feel </h3>
          <p>Now configure the look & feel of your allowlist registration page</p>
          <div className="row">
            <div className="col-lg-6">
              <FormInput name="projectLogo" type="file" placeholder="Project Logo" fileUploadName="projectLogoName" />
            </div>
            <div className="col-lg-6">
              <FormInput
                name="projectIcon"
                type="file"
                placeholder="Project Icon (Optional)"
                fileUploadName="projectIconName"
              />
            </div>
            <div className="col-lg-12">
              <FormInput
                name="projectBackgroundImage"
                type="file"
                placeholder="Background Image"
                fileUploadName="projectBackgroundName"
              />
            </div>

            <div className="col-lg-6">
              <FormInput type="color" className="comon-color-div1" name="primaryColour" label="Primary Colour " />
            </div>
            <div className="col-lg-6">
              <FormInput type="color" className="comon-color-div1" name="secondaryColour" label="Secondary Colour" />
            </div>
          </div>
        </div>
        <div className="inside-div-cm col-lg-10 w-90 mx-auto d-block mb-2">
          <h3> Socials & Webpage </h3>
          <p> Attach the website link & connect the social accounts for your project</p>
          <div className="comon-opcity-div mt-0">
            <div className="row">
              <div className="col-lg-12">
                <FormInput name="projectWebsite" type="text" placeholder="Project Website Link" className="mb-0" />
              </div>
            </div>
          </div>
        </div>
        <div className="inside-div-cm col-lg-10 w-90 mx-auto d-block">
          <div className="d-flex gap-2">
            <div className="col">
              <div className={`social-div1 ${errors?.discord ? "dot" : ""}`}>
                {!discord?.userData ? (
                  <div className="top-counnect-div-socl">
                    <figure>
                      <img style={{ maxWidth: "110px" }} src="images/discord-white.png" alt="icon2" />
                    </figure>
                    <button type="button" className="btn comon-count1" onClick={() => onDiscordClick(false)}>
                      Connect Discord
                    </button>
                  </div>
                ) : (
                  <div className="div-show-couunt-ts-cord d-block ">
                    <div
                      style={{ opacity: isEditMode ? 0.5 : 1 }}
                      className="d-flex align-items-center mb-2 justify-content-center"
                    >
                      <figure className="m-0">
                        <FaDiscord />
                      </figure>
                      <div className="liks-div d-flex align-items-center">
                        <h5 className="m-0 discord-username">
                          <span>{discord?.userData?.username}</span> connected
                        </h5>
                        <button disabled={isEditMode} className="btn" onClick={() => onDiscordClick(true)}>
                          <BsX />
                        </button>
                      </div>
                    </div>
                    <div className="form-group customize-select" style={{ display: "flex", justifyContent: "center" }}>
                      <input
                        type="text"
                        readOnly
                        className="form-control discord-select w-100"
                        value={discord?.guildData?.name || discord?.guildData[0]?.name}
                      />
                    </div>
                    <FormInput
                      onKeyPress={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      name="discordInviteLink"
                      type="text"
                      placeholder="Add Invite Link"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col">
              <div className={`social-div1 ${errors?.twitter ? "dot" : ""}`}>
                {!twitter?.user ? (
                  <div>
                    <figure>
                      <img style={{ maxWidth: "110px" }} src="images/twitter-white.png" alt="icon2" />
                    </figure>

                    <button type="button" className="btn comon-count1" onClick={() => onTwitterClick(false)}>
                      Connect Twitter
                    </button>
                  </div>
                ) : (
                  <div style={{ opacity: isEditMode ? 0.5 : 1 }} className="div-show-couunt-ts">
                    <div className="d-flex align-items-center">
                      <figure>
                        <img style={{ maxWidth: "40px" }} src="images/twitter-white.png" alt="icon2" />
                      </figure>
                      <div className="liks-div d-flex align-items-center">
                        <h5 className="mt-0" style={{ marginLeft: "6px !important" }}>
                          <span>{twitter?.user?.username}</span> connected
                        </h5>
                        <button disabled={isEditMode} className="btn" onClick={() => onTwitterClick(true)}>
                          <BsX />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="inside-div-cm col-lg-10 w-90 mx-auto d-block mb-sm-4 mb-3 mb-2">
          <div className="d-sm-flex align-items-center justify-content-end">
            <div className="right-pre-div d-flex align-items-center justify-content-between">
              {isEditMode ? (
                <button disabled={!showToast} type="submit" className="btn next-btn">
                  Done
                  <span className="d-flex">
                    <img src={ArrowRight} alt="Icon" />
                  </span>
                </button>
              ) : (
                <>
                  <button type="button" onClick={saveDraft} className="btn pre-btn">
                    Save Draft
                  </button>
                  <button disabled={!showToast} type="submit" className="btn next-btn">
                    Next
                    <span className="d-flex">
                      <img src={ArrowRight} alt="Icon" />
                    </span>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
export default BasicForm;
