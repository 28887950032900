import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import LaunchedItem from "./LaunchedItem";
import PauseConfirmation from "./Modal/PauseConfirmation";
import AddEditGroup from "./Modal/AddEditGroup";
import { ReactComponent as RedirectIcon } from "../../../assets/images/redirect-icon.svg";
import { ConfirmationDialogProvider } from "../../../components/ConfirmationModel";
import { ApiCall } from "../../../utils/ApiUtils";
import { setIsEditUserId } from "../../../store/reducer";

const Launched = ({ dataItem, handlePause, handleResume, fetchData, isSearchFetch, isFound, index }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [addGroupModal, setAddGroupModal] = useState(false);
  const [noOfReg, setNoOfReg] = useState([]);
  const [activeGrp, setActiveGrp] = useState([]);
  const [isFetchedGetNoReg, setIsFetchedGetNoReg] = useState(false);
  const isNextFetch = useSelector(({ isNextFetch }) => isNextFetch);
  const numberOfGroups = useSelector(({ noLimit }) => noLimit?.numberOfGroups);

  const getNoOfReg = () => {
    ApiCall("GET", `/rest/allowlist/${dataItem?.id}/getRegistrantsCount`)
      .then((result) => {
        setNoOfReg(result.data);
        setIsFetchedGetNoReg(true);
      })
      .catch((error) => {
        toast.error("Failed to get data.");
        console.log(error);
      });
  };

  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
    }
  }, [showToast]);

  useEffect(() => {
    if (isSearchFetch || isFound) {
      getNoOfReg();
    }
  }, [isSearchFetch, isFound]);

  useEffect(() => {
    if (isNextFetch[dataItem?.id] && !isFetchedGetNoReg) {
      getNoOfReg();
    }
  }, [isNextFetch]);

  const editProjectDetail = (id) => {
    dispatch(setIsEditUserId(id));
    navigate("/launch", { state: { index } });
  };

  useEffect(() => {
    const dd = dataItem?.allowlistGroup?.filter((item) =>
      item.isActive === true ? item.isActive === true : item.isSchedule === true
    );
    setActiveGrp(dd);
  }, [dataItem]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleAddGroupShow = () => {
    if (numberOfGroups > activeGrp.length) {
      setAddGroupModal(true);
    } else {
      setShowToast(true);
      !showToast &&
        toast.error(
          "You have reached the maximum limit to launch groups. Please deactivate or deschedule an existing group in order to proceed."
        );
    }
  };

  return (
    <ConfirmationDialogProvider>
      <div className="allowlist-content-wrap">
        <div className="title-wrap mb-md-5 mb-4">
          <div className="d-flex gap-3">
            <h1 style={{ color: !dataItem?.isActive ? "rgba(255, 255, 255, 0.3)" : "#fff" }} className="mb-0">
              {dataItem?.allowListName}
            </h1>
            <div className="custom-tooltip">
              <a
                href={`${window.location.protocol}//${dataItem?.urlSlug}.${process.env.REACT_APP_REGISTER_REDIRECT_URL}`}
                target="_blank"
              >
                <RedirectIcon height={26} width={26} />
              </a>
              <span className="tooltip-text custom-tooltip-bottom">
                This will redirect you to the user registration page.
              </span>
            </div>
            {!dataItem?.isActive && (
              <h5
                className="ms-3"
                style={{ color: !dataItem?.isActive ? "rgba(255, 255, 255, 0.3)" : "#fff", margin: 0 }}
              >
                Paused
              </h5>
            )}
          </div>
          <ul>
            <li>
              <button className="btn-gradient btn-gradient-after" onClick={() => editProjectDetail(dataItem?.id)}>
                Edit
              </button>
            </li>
            {dataItem?.isActive ? (
              <li>
                <button className="btn-gradient btn-gradient-after" onClick={handleShow}>
                  Pause
                </button>
              </li>
            ) : (
              <li>
                <button
                  className="btn-gradient btn-gradient-after"
                  onClick={() => {
                    handleResume(dataItem?.id);
                  }}
                >
                  Resume
                </button>
              </li>
            )}
          </ul>
        </div>
        <div className="launched-main-content">
          <ul>
            {dataItem?.allowlistGroup.map((item, index) => (
              <LaunchedItem
                key={index}
                fetchData={fetchData}
                groupItem={item}
                dataItem={dataItem}
                index={index}
                getNoOfReg={getNoOfReg}
                noOfRegCount={noOfReg.find((item1) => item1._id === item.id)?.count}
                isSearchFetch={isSearchFetch}
                isFound={isFound}
                activeGrp={activeGrp}
              />
            ))}
            <li className="mb-0 text-center mx-auto" style={{ maxWidth: "90%" }}>
              <button onClick={() => handleAddGroupShow()} className="btn btn-opt-add">
                + Add New Group
              </button>
            </li>
          </ul>
        </div>
        {show && (
          <PauseConfirmation show={show} handleClose={handleClose} dataItem={dataItem} handlePause={handlePause} />
        )}
        {addGroupModal && (
          <AddEditGroup
            addGroupModal={addGroupModal}
            setAddGroupModal={setAddGroupModal}
            dataItem={dataItem}
            fetchData={fetchData}
          />
        )}
      </div>
    </ConfirmationDialogProvider>
  );
};

export default Launched;
